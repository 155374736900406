import { useCallback, useEffect, useState } from "react";
import { Tab, Nav, Spinner, Button, NavDropdown, Dropdown } from "react-bootstrap";
import { Event, EventAdmin, isSameDay } from "../../../models/interfaces/Event";
import { Competition, isCompetition, sortCompetitions } from "../../../models/interfaces/Competition";
import { Match, MatchStatus } from "../../../models/interfaces/Match";
import { EventStandingsView } from "./EventStandingsView";
import { EventLeadersView } from "./EventLeadersView";
import { CompetitionEntry } from "../../../models/interfaces/Competitions/CompetitionEntry";
import { EventMatchDisplayView } from "./EventMatchDisplayView";
import { SportProvider } from "../../../models/interfaces/SportProvider";
import { User } from "../../../models/interfaces/User";
import styled from "styled-components";

interface EventProps {
    sportProvider: SportProvider;
    event: Event;
    user ?: User;
}

interface SelectedCompetition {
    competition: Competition;
    teams: CompetitionEntry[] | undefined;
    completedMatches: Match[] | undefined;
    scheduledMatches: Match[] | undefined;
}

export function eventTabStorageKey(eventID: string) {
    return `featuredEventViewTab:${eventID}`
}

export function eventCompetitionStorageKey(eventID: string) {
    return `featuredEventViewSelectedCompetition:${eventID}`
}

const SmallScreenPicker = styled(Dropdown)`
    @media only screen and (min-width: 431px) {
        display: none;
    }
`

const LargeScreenPicker = styled.div`
    @media only screen and (max-width: 430px) {
        display: none;
    }
`


export function FeaturedEventView({ sportProvider, event, user }: EventProps) {
    const [selectedCompetition, setSelectedCompetition] = useState<Competition | undefined>(undefined);
    const [competitions, setCompetitions] = useState<Competition[] | undefined>(undefined);
    const [competitionMatches, setCompetitionMatches] = useState<{ [competitionId: string]: SelectedCompetition }>({});
    const [selectedCompetitionEntry, setSelectedCompetitionEntry] = useState<CompetitionEntry | undefined>(undefined);

    const [selectedTab, setSelectedTab] = useState<string>(() => {
        const storedTab = localStorage.getItem(eventTabStorageKey(event.id));
        return storedTab ?? "schedule";
    });

    const handleSelectTab = (tab: string | null) => {
        setSelectedTab(tab ?? 'schedule');
    };

    useEffect(() => {
        localStorage.setItem(eventTabStorageKey(event.id), selectedTab);
    }, [event.id, selectedTab]);

    const selectedCompetitionStorageKey = eventCompetitionStorageKey(event.id); 
    useEffect(() => {
        if (competitions === undefined) {
            return
        }
        if (selectedCompetition === undefined) {
            localStorage.removeItem(selectedCompetitionStorageKey);
        } else {
            localStorage.setItem(selectedCompetitionStorageKey, selectedCompetition.id);
        }
        if (selectedCompetitionEntry?.competition.id !== selectedCompetition?.id) {
            setSelectedCompetitionEntry(undefined);
        }
    }, [selectedCompetition, competitions, selectedCompetitionStorageKey, selectedCompetitionEntry?.competition.id])

    const selectedTeamStorageKey = `featuredEventViewSelectedTeam:${event.id}`
    useEffect(() => {
        if (competitions === undefined || selectedCompetition === undefined) {
            return
        }
        if (selectedCompetitionEntry === undefined) {
            localStorage.removeItem(selectedTeamStorageKey);
        } else {
            localStorage.setItem(selectedTeamStorageKey, selectedCompetitionEntry.id);
        }
    }, [selectedCompetitionEntry, competitions, selectedCompetition, selectedTeamStorageKey])

    useEffect(() => {
        if (selectedCompetition === undefined) {
            return
        }

        if (competitionMatches[selectedCompetition.id]?.completedMatches !== undefined
            && competitionMatches[selectedCompetition.id]?.scheduledMatches !== undefined) {
            return
        }

        selectedCompetition.matchProvider.once().then((resolvers) => {
            return Promise.all(
                resolvers.map((resolver) => {
                    return resolver.asAPromise();
                })
            ).then((matches) => {
                // sort completed matches by most recent
                const completedMatches = matches.filter((match) => match.status === MatchStatus.completed).sort((a, b) => {
                    return b.date.getTime() - a.date.getTime();
                });

                // sort scheduled matches by start time
                const scheduledMatches = matches.filter((match) => match.status !== MatchStatus.completed).sort((a, b) => {
                    return a.date.getTime() - b.date.getTime();
                });


                // update competitionMatches with this new competition
                setCompetitionMatches(current => {
                    var updatedCompetition = current[selectedCompetition.id];
                    if (updatedCompetition === undefined) {
                        updatedCompetition = {
                            competition: selectedCompetition,
                            teams: undefined,
                            completedMatches: completedMatches,
                            scheduledMatches: scheduledMatches,
                        }
                    }
                    else {
                        updatedCompetition.scheduledMatches = scheduledMatches;
                        updatedCompetition.completedMatches = completedMatches;
                    }
                    return {
                        ...current,
                        [selectedCompetition.id]: updatedCompetition,
                    }
                });
            });
        });
    }, [selectedCompetition, competitionMatches])

    useEffect(() => {
        // if we have existing matches for this competition, return
        if (selectedCompetition === undefined) {
            return
        }

        if (competitionMatches[selectedCompetition.id]?.teams !== undefined) {
            return
        }

        selectedCompetition.teamProvider.once().then((resolvers) => {
            return Promise.all(
                resolvers.map((resolver) => {
                    return resolver.asAPromise();
                })
            )
        }).then(teams => {
            const sortedTeams = teams.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            setCompetitionMatches(current => {
                var updatedCompetition = current[selectedCompetition.id];
                if (updatedCompetition === undefined) {
                    updatedCompetition = {
                        competition: selectedCompetition,
                        teams: sortedTeams,
                        completedMatches: undefined,
                        scheduledMatches: undefined,
                    }
                } else {
                    updatedCompetition.teams = sortedTeams;
                }
                return {
                    ...current,
                    [selectedCompetition.id]: updatedCompetition,
                }
            });
            const preferredTeamId = localStorage.getItem(selectedTeamStorageKey);
            var preferredTeam: CompetitionEntry | undefined = undefined;
            if (preferredTeamId !== null) {
                preferredTeam = sortedTeams.find((team) => team.id === preferredTeamId);
            }
            setSelectedCompetitionEntry(preferredTeam);
        });
    }, [selectedCompetition, competitionMatches, selectedTeamStorageKey])

    useEffect(() => {
        event.competitionProvider.once().then((resolvers) => {
            return Promise.allSettled(
                resolvers.map((resolver) => {
                    return resolver.asAPromise();
                })
            ).then((results) => {
                const sortedCompetitions = results
                    .filter((result): result is PromiseFulfilledResult<Competition> => result.status === "fulfilled")
                    .map((result) => result.value)
                    .filter((competition): competition is Competition => competition !== null && isCompetition(competition))
                    .sort(sortCompetitions);
                setCompetitions(sortedCompetitions);
                const preferredCompetitionId = localStorage.getItem(selectedCompetitionStorageKey);
                var preferredCompetition: Competition | undefined = undefined;
                if (preferredCompetitionId !== null) {
                    preferredCompetition = sortedCompetitions.find((competition) => competition.id === preferredCompetitionId);
                }
                if (!preferredCompetition && sortedCompetitions.length > 0) {
                    preferredCompetition = sortedCompetitions[0];
                }
                setSelectedCompetition(preferredCompetition);
            });
        });
    }, [event.competitionProvider, selectedCompetitionStorageKey]);


    var dateString: string | undefined = undefined;
    if (event.attributes.displayCompetitionDates) {
        const startDate = event.attributes.competitionDateRange.startDate;
        const endDate = event.attributes.competitionDateRange.endDate;
        const singleDayEvent = isSameDay(startDate, endDate);

        if (singleDayEvent) {
            dateString = startDate.toLocaleDateString();
        } else {
            dateString = `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
        }
    }

    const matches = competitionMatches[selectedCompetition?.id ?? ""] ?? undefined;
    const teams = competitionMatches[selectedCompetition?.id ?? ""]?.teams ?? undefined;

    const [eventAdmin, setEventAdmin] = useState<EventAdmin | undefined>(undefined);

    const updateEventAdmin = useCallback(
        (user) => {
            event
                .adminForUser(user)
                .then((eventAdmin) => {
                    setEventAdmin(eventAdmin);
                })
                .catch(() => {
                    setEventAdmin(undefined);
                });
        },
        [event]
    );

    useEffect(() => {
        if (user && !eventAdmin) {
            updateEventAdmin(user);
        }
        if (!user && eventAdmin) {
            setEventAdmin(undefined);
        }
    }, [event, user, updateEventAdmin, eventAdmin]);

    return (
        <div style={{ display: "block" }}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '5px'}}>
                <div className='myNav' style={{display: 'flex', flexDirection: 'column', gap: '2px'}}>
                    <a href={`/events/${event.id}`}>
                    <h4 style={{marginBottom: '0px', fontWeight: 'bold'}}>{event.attributes.name}</h4>
                    </a>
                    { dateString && (<h6>{dateString}</h6>)
                    }
                </div>
                { 
                    eventAdmin && (
                        <a href={`/events/${event.id}/admin`}>
                            <Button variant="info">Manage Event</Button>
                        </a>
                    )
                
                }
            </div>
            <Tab.Container activeKey={selectedTab} onSelect={handleSelectTab}>
                <Nav className="eventTabPicker">
                    <SmallScreenPicker>
                        <Dropdown.Toggle>
                            {selectedTab.toUpperCase()}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Nav.Item>
                                <Nav.Link eventKey="schedule">Schedule</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="results">Results</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="leaderboard">Leaderboard</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="standings">Standings</Nav.Link>
                            </Nav.Item>
                        </Dropdown.Menu>
                    </SmallScreenPicker>
                    <LargeScreenPicker>
                    <div style={{display: 'flex', flexDirection: 'row', gap: '5px'}}>
                            <Nav.Item>
                                <Nav.Link eventKey="schedule">Schedule</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="results">Results</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="leaderboard">Leaderboard</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="standings">Standings</Nav.Link>
                            </Nav.Item>
                        </div>
                    </LargeScreenPicker>
                </Nav>
                <Tab.Content style={{ width: '100%' }}>
                    {competitions === undefined || selectedCompetition === undefined ? (
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    ) : (
                        <>
                            <Tab.Pane eventKey="schedule" transition={false}>
                                <EventMatchDisplayView
                                    sportProvider={sportProvider}
                                    filter={{
                                        competitionSelect: {
                                            competitions,
                                            selectedCompetition: selectedCompetition,
                                            onCompetitionSelect(competition) {
                                                setSelectedCompetition(competition);
                                            },
                                        },
                                        teamSelect: {
                                            competitionEntries: teams,
                                            selectedCompetitionEntry,
                                            onSelectCompetitionEntry: setSelectedCompetitionEntry,
                                        }
                                    }}
                                    matches={matches?.scheduledMatches}
                                    noContentText={'No scheduled matches for this division.'}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="results" transition={false}>
                                <EventMatchDisplayView
                                    sportProvider={sportProvider}
                                    filter={{
                                        competitionSelect: {
                                            competitions,
                                            selectedCompetition: selectedCompetition,
                                            onCompetitionSelect(competition) {
                                                setSelectedCompetition(competition);
                                            },
                                        },
                                        teamSelect: {
                                            competitionEntries: teams,
                                            selectedCompetitionEntry,
                                            onSelectCompetitionEntry: setSelectedCompetitionEntry,
                                        }
                                    }}
                                    matches={matches?.completedMatches}
                                    noContentText={'No completed matches for this division.'}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="leaderboard" transition={false}>
                                <EventLeadersView
                                    competitions={competitions}
                                    selectedCompetition={selectedCompetition}
                                    onSelectCompetition={setSelectedCompetition}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="standings" transition={false}>
                                <EventStandingsView
                                    competitions={competitions}
                                    selectedCompetition={selectedCompetition}
                                    onSelectCompetition={setSelectedCompetition}
                                />
                            </Tab.Pane>
                        </>
                    )}
                </Tab.Content>
            </Tab.Container>
        </div>
    );
}